import { mdiHomeOutline } from "@mdi/js"
import nextStop from "@/navigation/vertical/next-stop"

export default [
  {
    title: "Dashboard",
    icon: mdiHomeOutline,
    to: "dashboard",
  },
  ...nextStop,
]
