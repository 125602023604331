import appConfigStoreModule from "@core/@app-config/appConfigStoreModule"
import Vue from "vue"
import Vuex from "vuex"
import actions from "./actions"
import getters from "./getters"
import app from "./app"
import mutations from "./mutations"
import state from "./state"

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },
})
