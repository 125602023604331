import authGuard from "../auth/authGuard"

const stopsManager = [
  {
    path: "/next-stop/stops",
    name: "next-stop-stops",
    component: () => import("@/views/next-stop/stops/StopList.vue"),
    meta: {
      layout: "content",
      scope: "stops",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/stops/:id",
    name: "next-stop-stop-config",
    component: () => import("@/views/next-stop/stops/StopConfiguration.vue"),
    meta: {
      layout: "content",
      scope: "stops",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/vehicles",
    name: "next-stop-vehicles",
    component: () => import("@/views/next-stop/vehicles/VehicleList.vue"),
    meta: {
      layout: "content",
      scope: "vehicles",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/vehicles/:id",
    name: "next-stop-vehicle-config",
    component: () => import("@/views/next-stop/vehicles/VehicleConfiguration.vue"),
    meta: {
      layout: "content",
      scope: "vehicles",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/routes",
    name: "next-stop-routes",
    component: () => import("@/views/next-stop/routes/RouteList.vue"),
    meta: {
      layout: "content",
      scope: "routes",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/routes/:id",
    name: "next-stop-route-config",
    component: () => import("@/views/next-stop/routes/RouteConfiguration.vue"),
    meta: {
      layout: "content",
      scope: "routes",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/devices/",
    name: "next-stop-devices",
    component: () => import("@/views/next-stop/devices/DeviceList.vue"),
    meta: {
      layout: "content",
      scope: "devices",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/devices/:id",
    name: "next-stop-devices",
    component: () => import("@/views/next-stop/devices/DeviceList.vue"),
    meta: {
      layout: "content",
      scope: "devices",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/devices/device/:id",
    name: "next-stop-device-config",
    component: () => import("@/views/next-stop/devices/DeviceConfiguration.vue"),
    meta: {
      layout: "content",
      scope: "devices",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/notes/",
    name: "next-stop-notes",
    component: () => import("@/views/next-stop/notes/NotesList.vue"),
    meta: {
      layout: "content",
      scope: "notes",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/next-stop/notes/note/:id",
    name: "next-stop-note-config",
    component: () => import("@/views/next-stop/notes/NoteConfiguration.vue"),
    meta: {
      layout: "content",
      scope: "notes",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/settings",
    name: "next-stop-settings",
    component: () => import("@/views/next-stop/settings/Settings.vue"),
    meta: {
      layout: "content",
      scope: "settings",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/provision/:balenaid/:hardwareid",
    name: "next-stop-provision",
    component: () => import("@/views/next-stop/provision/Landing.vue"),
    meta: {
      layout: "content",
      scope: "provision",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/next-stop/reports",
    name: "next-stop-reports",
    component: () => import("@/views/next-stop/reports/Reports.vue"),
    meta: {
      layout: "content",
      scope: "reports",
    },
    beforeEnter: authGuard,
  },
]

export default stopsManager
