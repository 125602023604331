export default {
  mapbox: {
    zoom: 13.5,
    zoomForInfoPanel: 15.5,
    center: [174.775, -41.293],
    style: "mapbox://styles/mapbox/streets-v11",
  },
  routeTypeMapping: [
    { type: "bus", ids: [3] },
    { type: "train", ids: [0, 1, 2] },
    { type: "school", ids: [6, 712] },
    { type: "cable-car", ids: [5] },
    { type: "ferry", ids: [4] },
    { type: "other", ids: [4] },
  ],
  authConfig: {
    scope:
      "openid profile email stops routes vehicles vehicles:edit vehicles:command vehicles:diagnostic pronunciations settings provision audio devices notes audit reports",
  },
}
