<template v-if="$auth.isAuthenticated">
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
          <v-img :src="$auth.avatar"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list class="pl-3 pr-3">
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
            <v-img :src="$auth.avatar"></v-img>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ $auth.user.name }}
          </span>
        </div>
      </div>

      <v-divider class="my-2"></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $auth.user.email }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->

      <v-list-item>
        <v-list-item-content>
          <v-btn block small color="primary" append-icon="ri-logout-box-r-line" @click="logout">
            <v-icon :size="16" class="me-2">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
            Logout
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiAccountOutline, mdiLogoutVariant, mdiEmailBox } from "@mdi/js"

export default {
  methods: {
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiLogoutVariant,
        mdiEmailBox,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
