const mutations = {
  START_LOADING(state) {
    state.loading = true
  },

  END_LOADING(state) {
    state.loading = false
  },

  SET_USER(state, payload) {
    state.user = payload
  },

  SET_ACCESS_TOKEN(state, payload) {
    state.loading = payload
  },

  SET_STOPS(state, payload) {
    state.gtfs.stops = payload
  },

  SET_SELECTED_STOP(state, payload) {
    state.selected_stop = payload
  },

  SET_VEHICLES(state, payload) {
    state.gtfs.vehicles = payload
  },

  SET_POSITIONS(state, payload) {
    state.gtfs.positions = payload
  },

  SET_SELECTED_VEHICLE(state, payload) {
    state.selected_vehicle = payload
  },

  SET_VEHICLE_HISTORY(state, payload) {
    state.selected_vehicle.history = payload
  },

  SET_SELECTED_VEHICLE_STATUS(state, payload) {
    if (state.selected_vehicle) {
      state.selected_vehicle.status = payload.status
    } else {
      state.selected_vehicle = payload
    }
  },

  SET_ROUTES(state, payload) {
    state.gtfs.routes = payload
  },

  SET_SELECTED_ROUTE(state, payload) {
    state.selected_route = payload
  },

  SET_NEXT_STOP_SETTINGS(state, payload) {
    state.settings = payload
  },

  SET_PRONUCIATIONS(state, payload) {
    state.pronunciations = payload
  },

  SET_LEXICONS(state, payload) {
    state.lexicons = payload
  },

  SET_SNACKBAR(state, payload) {
    state.snackbar = { ...payload, visible: true }
  },

  CLOSE_SNACKBAR(state) {
    state.snackbar = {}
  },

  SET_DEVICES(state, payload) {
    state.gtfs.devices = payload
  },

  SET_SELECTED_DEVICE(state, payload) {
    state.selected_device = payload
  },

  SET_PREVIOUS_ROUTE(state, route) {
    state.previousNavigationRoute = route
  },

  SET_NOTES(state, payload) {
    state.gtfs.notes = payload
  },

  SET_SELECTED_NOTE(state, payload) {
    state.selected_note = payload
  },

  ADD_NOTE(state, payload) {
    state.gtfs.notes.unshift(payload)
  },

  REMOVE_NOTE(state, payload) {
    state.gtfs.notes = state.gtfs.notes.filter(note => note.note_id !== payload)
  },
}

export default mutations
