import {
  mdiViewDashboard,
  mdiLogin,
  mdiCircleSlice8,
  mdiBus,
  mdiRoutes,
  mdiTelevision,
  mdiCog,
  mdiTextBoxMultipleOutline,
} from "@mdi/js"

/* eslint-disable */
// prettier-ignore
export default [
  { title: 'Dashboard', icon: mdiViewDashboard, to: 'dashboard' },
  { title: 'Login', icon: mdiLogin, to: 'login' },
  { title: 'Stops', icon: mdiCircleSlice8, to: '/next-stop/stops' },
  { title: 'Vehicles', icon: mdiBus, to: '/next-stop/vehicles' },
  { title: 'Routes', icon: mdiRoutes, to: '/next-stop/routes' },
  { title: 'Devices', icon: mdiTelevision, to: '/next-stop/devices' },
  { title: 'Notes', icon: mdiTextBoxMultipleOutline, to: '/next-stop/notes' },
  { title: 'Settings', icon: mdiCog, to: '/next-stop/settings' },
]
