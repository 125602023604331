<template>
  <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
    <div v-if="loading" class="fill-height d-flex justify-center align-center">
      <Loader />
    </div>
    <transition v-else :name="appRouteTransition" mode="out-in" appear>
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from "@vue/composition-api"
// eslint-disable-next-line import/no-unresolved
import useAppConfig from "@core/@app-config/useAppConfig"
import { useRouter } from "@core/utils"
import { useLayout } from "@core/layouts/composable/useLayout"
import useDynamicVh from "@core/utils/useDynamicVh"
import { mapState } from "vuex"

// Layouts
import LayoutContentVerticalNav from "@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue"
import LayoutBlank from "@/layouts/variants/blank/LayoutBlank.vue"
import Loader from "@/components/Loader.vue"

// Dynamic vh

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutBlank,
    Loader,
  },
  metaInfo: {
    title: "Next Stop Management Application",
    titleTemplate: "%s | NSMA",
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
    }),
  },
  watch: {
    $route(to, from) {
      const routeChangeEvent = new CustomEvent("routeChanged", { detail: { from, to } })
      window.dispatchEvent(routeChangeEvent)
    },
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === "blank") return "layout-blank"
      if (route.value.meta.layout === "content") return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
