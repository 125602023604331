import Vue from "vue"
import createAuth0Client from "@auth0/auth0-spa-js"
import jwt from "jsonwebtoken"
import store from "@/store"

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        auth0Client: null,
        error: null,
        loading: true,
        popupOpen: false,
        isAuthenticated: false,
        user: {},
        accessToken: null,
        tokenData: {},
        scopes: [],
        avatar: null,
      }
    },

    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        ...options,
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        scope: options.scope,
        redirect_uri: redirectUri,
      })

      try {
        // If the user is returning to the app after authentication..
        if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()

          this.error = null

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated()

        await this.loadUserData()

        store.$apiClient.interceptors.request.use(
          config => {
            const { accessToken } = this
            if (accessToken) {
              config.headers.Authorization = `Bearer ${accessToken}` // eslint-disable-line no-param-reassign
            }

            return config
          },
          error => Promise.reject(error),
        )
        store.dispatch("init")

        this.loading = false
      }
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(loginOptions, config) {
        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(loginOptions, config)
          await this.loadUserData()

          this.isAuthenticated = await this.auth0Client.isAuthenticated()
          this.error = null
        } catch (e) {
          this.error = e
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false
        }

        this.user = await this.auth0Client.getUser()
        this.isAuthenticated = true
      },

      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          await this.loadUserData()

          this.isAuthenticated = true
          this.error = null
        } catch (e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },

      /** Authenticates the user using the redirect method */
      async loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o)
      },

      /** Returns all the claims present in the ID token */
      async getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o)
      },

      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      async getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o)
      },

      /** Gets the access token using a popup window */
      async getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o)
      },

      /** Logs the user out and removes their session on the authorization server */
      async logout(o) {
        await this.resetUserData()

        return this.auth0Client.logout(o)
      },

      async loadUserData() {
        if (!this.isAuthenticated) {
          return
        }
        this.user = await this.auth0Client.getUser()
        this.accessToken = await this.getTokenSilently()
        this.avatar = await this.getUserAvatar()

        this.tokenData = jwt.decode(this.accessToken)
        this.scopes = this.tokenData.scope.split(" ")
      },

      async resetUserData() {
        this.isAuthenticated = false
        this.user = {}
        this.accessToken = null
        this.tokenData = {}
        this.scopes = []
        this.avatar = null
      },

      async getUserAvatar() {
        if (!this.user) {
          return null
        }

        if (
          typeof this.user.given_name !== "undefined" &&
          this.user.given_name.length &&
          typeof this.user.family_name !== "undefined" &&
          this.user.family_name.length
        ) {
          const picURL = this.user.picture.split("%2F")
          picURL.pop()
          picURL.push(
            `${this.user.given_name.charAt(0).toLowerCase() + this.user.family_name.charAt(0).toLowerCase()}.png`,
          )

          return picURL.join("%2F")
        }

        return this.user.picture
      },

      hasScope(scope) {
        return this.scopes.includes(scope)
      },
    },
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(MyVueObj, options) {
    MyVueObj.prototype.$auth = useAuth0(options) // eslint-disable-line no-param-reassign
  },
}
