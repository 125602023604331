import Vue from "vue"
import VueRouter from "vue-router"
import nextStop from "./next-stop"
import authGuard from "../auth/authGuard"
import store from "@/store"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "dashboard",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Dashboard.vue"),
    meta: {
      layout: "content",
    },
    beforeEnter: authGuard,
  },
  ...nextStop,
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/error-401",
    name: "error-401",
    component: () => import("@/views/Error401.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error404.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// Track all routes to see what the previous route was
router.beforeEach((to, from, next) => {
  store.commit("SET_PREVIOUS_ROUTE", from)
  next()
})

export default router
