import { mdiBus, mdiBusSchool, mdiFerry, mdiGondola, mdiTrainCar, mdiTrainVariant } from "@mdi/js"

export default {
  methods: {
    // E.g. route route_short_name could be 12, 12e, 30c, N22, WRD, if we are sorting by this property
    // we need to consider all the combining situations, so a sorted list would be:
    // 1, 2, 10, 12, 12e, 28, 30, 30x, 31x, 32x, 33, 120, N22, WRD
    sortByMixedTypeField(list, propertyName) {
      return list.sort((a, b) => {
        if (Number.isNaN(parseInt(a[propertyName], 10)) || Number.isNaN(parseInt(b[propertyName], 10))) {
          // at lease one is not a number from Number.isNuN(), e.g. N22, or WRL
          if (!Number.isNaN(parseInt(a[propertyName], 10))) return -1
          if (!Number.isNaN(parseInt(b[propertyName], 10))) return 1
          if (a[propertyName] > b[propertyName]) return 1
          if (a[propertyName] < b[propertyName]) return -1

          return 0
        }

        // include 12e, 30x or 32x, etc
        const sortA = parseInt(a[propertyName], 10)
        const sortB = parseInt(b[propertyName], 10)

        if (sortA > sortB) return 1
        if (sortA < sortB) return -1

        return 0
      })
    },

    routeTypePresentation(routeType) {
      const map = this.$config.routeTypeMapping.find(item => item.ids.includes(routeType))

      switch (map.type) {
        case "bus":
          return { icon: mdiBus, text: "Bus" }
        case "train":
          return { icon: mdiTrainVariant, text: "Train" }
        case "school":
          return { icon: mdiBusSchool, text: "School bus" }
        case "ferry":
          return { icon: mdiFerry, text: "Ferry" }
        case "cable-car":
          return { icon: mdiGondola, text: "Cable car" }
        default:
          return { icon: mdiTrainCar, text: "Other" }
      }
    },

    formatDate(dateTime, format = "sv") {
      if (!dateTime) {
        return ""
      }

      const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/
      const date = iso8601Regex.test(dateTime) ? new Date(dateTime) : new Date(parseInt(dateTime, 10) * 1000)

      return date.toLocaleString(format)
    },
  },
}
