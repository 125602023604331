<template>
  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar.visible"
    :color="snackbar.color || ''"
    :timeout="4000"
    style="opacity: 0.7"
    transition="fade-transition"
    rounded
    right
    :light="$vuetify.theme.dark"
  >
    <!-- eslint-disable vue/no-v-html -->
    <span class="me-1" v-html="snackbar.text"></span>
    <!--eslint-enable-->
    <v-btn v-if="snackbar.action" class="me-1 ms-1" @click="snackbar.action">
      {{ snackbar.actionText }}
    </v-btn>
    <v-icon class="ms-1" @click="snackbar.visible = false">
      {{ icons.mdiClose }}
    </v-icon>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex"
import { mdiClose } from "@mdi/js"

export default {
  computed: {
    ...mapState({
      snackbar: state => state.snackbar,
    }),
  },
  setup() {
    return {
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style></style>
