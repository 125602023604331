import { getInstance } from "./index"

export default (to, from, next) => {
  const authService = getInstance()

  const fn = () => {
    // If the user is not authenticated, log in
    if (!authService.isAuthenticated) {
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })

      return undefined
    }

    // If user doesn't have access, show error
    if (to.meta.scope && !authService.hasScope(to.meta.scope)) {
      return next({
        name: "error-401",
      })
    }

    // Continue with the route
    return next()
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn()
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn()
    }

    return undefined
  })

  return undefined
}
